
  import { computed, defineComponent, ref, watch } from 'vue';
  import { ErrorMessage, Field, Form } from 'vee-validate';
  import { hideModal } from '@/core/helpers/dom';
  import Swal from 'sweetalert2/dist/sweetalert2.js';
  import * as Yup from 'yup';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useStore } from 'vuex';
  import { useI18n } from 'vue-i18n/index';
  import Multiselect from '@vueform/multiselect';

  export default defineComponent({
    name: 'properties-editing',
    props: {
      editData: { type: Number, required: true },
    },
    emit: ['list-changed'],
    components: {
      ErrorMessage,
      Field,
      Form,
      Multiselect,
    },
    async setup(props, { emit }) {
      const submitButtonRef = ref<null | HTMLButtonElement>(null);
      const newPropertyModalRef = ref<null | HTMLElement>(null);
      const store = useStore();
      const { t, te } = useI18n();
      const showFileInput = ref(true);

      const property = ref<any | null>({
        id: 0,
        propertyId: 0,
        block: 0,
        plot: 0,
        size: 0,
        startingPrice: 0,
        startingPricePerSqrM: 0,
        statusId: 0,
        salePrice: 0,
        salePricePerSqrM: 0,
        percentagePremiumPaid: 0,
        isActive: true,
        additionalInfoEn: '',
        additionalInfoAr: '',
        createdAt: '',
        areaName: 0,
      });

      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      watch(
        () => props.editData,
        async (groupId) => {
          store.dispatch(Actions.ADD_BODY_CLASSNAME, 'page-loading-over');

          const { data } = await store.dispatch(
            Actions.GET_AUCTION_PROPERTY,
            groupId
          );
          property.value = data.data;

          if (!property.value.percentagePremiumPaid)
            updatePercentagePremiumPaid();
          if (!property.value.salePricePerSqrM) updateSalePricePerSqm();
          if (!property.value.startingPricePerSqrM)
            updateStartingPricePerSqrM();
          store.dispatch(Actions.REMOVE_BODY_CLASSNAME, 'page-loading-over');
        }
      );

      const validationSchema = Yup.object().shape({
        // propertyTypeId: Yup.number()
        //   .required(() => translate('PROPERTY_TYPE_IS_REQUIRED_FIELD'))
        //   .label('Property Type'),
        statusId: Yup.number()
          .required(() => translate('AUCTION_STATUS_IS_REQUIRED_FIELD'))
          .label('Status'),
        // areaId: Yup.number()
        //   .required(() => translate('AREA_IS_REQUIRED_FIELD'))
        //   .label('Status'),
        block: Yup.string().nullable().label('Block'),
        size: Yup.string()
          .required(() => translate('SIZE_IS_REQUIRED_FIELD'))
          .label('Size'),
        salePrice: Yup.string().nullable().label('Sale Price'),
        startingPrice: Yup.string()
          .required(() => translate('STARTING_PRICE_IS_REQUIRED_FIELD'))
          .label('Starting Price'),
        startingPricePerSqrM: Yup.string().label(
          'Starting Price Per Square Meter'
        ),
        salePricePerSqrM: Yup.string()
          .nullable()
          .label('Sale Price Per Square Meter'),
        additionalInfoEn: Yup.string()
          .nullable()
          .label('Additional Information English'),
        additionalInfoAr: Yup.string()
          .nullable()
          .label('Additional Information Arabic'),
        percentagePremiumPaid: Yup.string()
          .nullable()
          .label('Percentage Premium Paid'),
        plot: Yup.string().nullable().label('Plot'),
        attachment: Yup.mixed().label('Attachment'),
      });

      const updateStartingPricePerSqrM = async () => {
        if (
          property.value &&
          property.value.size &&
          property.value.startingPrice
        ) {
          property.value.startingPricePerSqrM = (
            property.value.startingPrice / Number(property.value.size)
          ).toString();
        }
      };

      const updateSalePricePerSqm = async () => {
        if (property.value && property.value.size && property.value.salePrice) {
          property.value.salePricePerSqrM = (
            property.value.salePrice / Number(property.value.size)
          ).toString();
        }
      };

      const updatePercentagePremiumPaid = async () => {
        if (
          property.value &&
          property.value.salePrice &&
          property.value.startingPrice
        ) {
          const difference =
            property.value.salePrice - property.value.startingPrice;
          property.value.percentagePremiumPaid = (
            (difference / property.value.startingPrice) *
            100
          ).toFixed(2);
        }
      };

      const submit = async (values) => {
        if (property.value.areaId == 0 || property.value.propertyId == 0)
          return;

        if (!submitButtonRef.value) {
          return;
        }

        //Disable button
        submitButtonRef.value.disabled = true;
        // Activate indicator
        submitButtonRef.value.setAttribute('data-kt-indicator', 'on');

        let attachmentsFormData: FormData | null = null;

        if (values.attachment) {
          attachmentsFormData = new FormData();
          attachmentsFormData.append('attachment', values.attachment);
        }

        if (values.statusId != 1) {
          delete values.salePrice;
          delete values.salePricePerSqrM;
        }
        values.areaId = Number(property.value?.areaId);
        values.propertyTypeId = Number(property.value?.propertyId);
        delete values.attachment;

        const data = await store.dispatch(Actions.UPDATE_AUCTION_PROPERTY, {
          data: values,
          propertyId: property.value?.id,
        });

        if (data?.id && attachmentsFormData) {
          await store.dispatch(Actions.UPLOAD_AUCTION_ATTACHMENTS, {
            data: attachmentsFormData,
            auctionId: data.id,
          });
        }

        const [errorName] = Object.keys(store.getters.getErrors);
        const error = store.getters.getErrors[errorName];

        if (error) {
          Swal.fire({
            text: translate(error[0]),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: translate('tryAgainExcl'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          });
          //Deactivate indicator
          submitButtonRef.value?.removeAttribute('data-kt-indicator');
          // eslint-disable-next-line
          submitButtonRef.value!.disabled = false;
        } else {
          Swal.fire({
            text: translate('SUCCESSFULLY_UPDATED_PROPERTY'),
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: translate('ok'),
            customClass: {
              confirmButton: 'btn btn-light-primary',
            },
          }).then(() => {
            debugger;
            emit('list-changed');

            showFileInput.value = false; // Hide the file input
            setTimeout(() => {
              showFileInput.value = true; // Show it again to reset
            }, 0); // Short delay to force DOM re-render

            hideModal(newPropertyModalRef.value);
            //Deactivate indicator
            submitButtonRef.value?.removeAttribute('data-kt-indicator');
            // eslint-disable-next-line
            submitButtonRef.value!.disabled = false;
          });
        }
      };

      await Promise.all([
        store.dispatch(Actions.GET_ALL_AUCTION_PROPERTY_TYPES),
        store.dispatch(Actions.GET_ALL_AUCTION_STATUSES),
        store.dispatch(Actions.GET_ALL_AREAS_LIST, { type: 'auction' }),
      ]);

      store.dispatch(Actions.REMOVE_BODY_CLASSNAME, 'page-loading-over');

      const downloadAttachment = (item) => {
        window.open(item, '_blank');
      };

      const getAttachmentThumbnail = () => {
        return '/media/svg/files/pdf.svg';
      };

      return {
        showFileInput,
        getAttachmentThumbnail,
        downloadAttachment,
        updateStartingPricePerSqrM,
        updateSalePricePerSqm,
        updatePercentagePremiumPaid,
        property,
        translate,
        validationSchema,
        submit,
        submitButtonRef,
        newPropertyModalRef,
        propertyTypes: computed(() => store.getters.allAuctionPropertyTypes),
        statuses: computed(() => store.getters.auctionStatusesList),
        areas: computed(() => store.getters.allListAreas),
      };
    },
  });
